.coin-container {
  perspective: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.coin {
  width: 200px;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 2s;
  position: relative;
}

.coin-side {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.coin-favor {
  transform: rotateY(0deg);
}

.coin-challenge {
  transform: rotateY(180deg);
}

.coin.flipping {
  animation: spin 2s infinite;
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.coin-image {
  width: 200px;
  height: 200px;
}