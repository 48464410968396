.card {
    font-size: 32px;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 8px;
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    position: relative;
    z-index: 1;
    opacity: 1;
    min-width: 100px;
    min-height: 100pxx;
}
.card p {
    margin: 0;
}

.card:hover {
    transform: scale(1.05);
}

.cardFlip {
    perspective: 1000px;
}

.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    cursor: pointer;
}

.card.is-flipped .cardInner {
    transform: rotate(180deg);
}

.cardFront,
.cardBack {
    position: absolute;
    width: 200px;
    height: 300px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    color: white;
    -webkit-backface-visibility: visible;
    /* Safari */
    backface-visibility: visible;
}

.cardBack {
    background-color: #fefefe;
    color: black;
    font-size: 58px;
    transform: rotateY(180deg)
}

.card-text {
    font-size: 58px;
    border: 2px solid black;
}