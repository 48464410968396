.dice {
    width: 200px;
    height: 200px;
    background-color: white;
    border:2px solid #000000;
    position: relative;
    perspective: 1000px;
    cursor: pointer;
    transition: transform 0.2s;
    animation-name: dice-roll;
    animation-duration: 0.5s;
    /* Adjust the duration to your liking */
    animation-timing-function: ease-out;
    animation-iteration-count: infinite
}

.dice-face {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 2px solid #000000fd;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}

.dice:hover {
    background-color: #45a049;
    transform: scale(1.1);
    rotate: 20%;
}

.dice:active {
    background-color: #3e8e41;
    box-shadow: 0 2px #666;
    transform: translateY(4px);
    rotate: 20%;
}

@keyframes dice-roll {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes roll {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }

    100% {
        transform: rotateX(360deg) rotateY(360deg);
    }
}