body {
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg,
      #f4fb8f,
      #ff6b6b);
  position: relative;
  justify-content: center;
  align-items: normal;
  height: 100%;
  margin-left: 30px;
  padding: 30px;
}

html {
  height: 95%;
  width: 95%;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  background-color: #e9ecef;
  overflow: auto;
  font-family: 'Arial', sans-serif;
  font-size: 25px;
  background-color: #e9ecef;
}

.App {
  width: 90%;
  height: 90%;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  overflow: scroll;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 30px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}


.player {
  background-color: #0e0d0d;
  border-radius: 1px;
  padding: 15px;
  margin: 5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.player h3 {
  color: #333;
}

.player-info {
  background-color: #075497;
  padding: 0;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 0;
}

.player-info h1 {
  color: #ecef06;
}


button {
  background-color: #28a745;
  color: rgb(0, 0, 0);
  border: line;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 25%;
  height: fit-content;
}
button2 {
  background-color:rgb(196, 112, 2);
  color: rgb(0, 0, 0);
  border: line;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 20%;
  max-height: 5%;
  height: min-content;
  font-size: smaller;
  margin-left: 20px;
}

button:hover {
  background-color: #218838;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(92, 8, 128);
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



h1 {
  color: #1ca00a;
  font-size: 2.5rem;
  margin: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
}


h2 {
  color: #333;
  font-size: 24px;
  text-align: left;
}

.App.player-0 {
  background-color: lightblue; /* Player 1's turn */
}

.App.player-1 {
  background-color: lightgreen; /* Player 2's turn */
}

.button {
  background-color: #4e6bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  width: 80%;
  margin-top: 20px;
}

.button:hover {
  background-image: linear-gradient(45deg, #4e6bff, #ff6b6b);
  transform: scale(1.05);
}

.button-end-turn {
  background-color: red;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
}
.button-disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.6;
}
h3 {
  color: rgb(255, 60, 0);
  font-size: 32px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
h4 {
  color:blueviolet;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
h5 {
  color:darkblue;
  font-size: 18px;
  text-align: left;
  align-items: left;
  
}
h6 {font-size: 30px;
text-align: center;
font-weight: bold;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
h7 {font-size: 30px;
  color: rgb(0, 238, 255);
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .game-components-image {
    width: 100%;
    max-width: 1000px;
    height: auto;
    display: block;
    margin: 0 auto;
  }